export const environment = {
  production: false,
  serverAPI : 'https://wowapi.whizzy.in/',
  mysqlAPI: 'https://apidev.whizzy.in/whizzy/api/v2/',
  backednAPI: "https://apidev.whizzy.in/whizzy/api/v3/",
  evAPI: "https://evdevapi.whizzy.in/api/ev",
  appUrl: 'https://apidev.whizzy.in',
  vendorApi: 'https://vendordevapi.whizzy.in/api/',
  // vendorApi: 'http://localhost:5000/api/',

 
  googleMaps: {
    apiKey: 'AIzaSyCSw8v2Et-rOsSpCm5Ezx9f5S6b3SxKSe4',
    clusterImagePath: 'https://raw.githubusercontent.com/googlearchive/js-marker-clusterer/gh-pages/images/m',
  },
	firebase: {
	  apiKey: "AIzaSyCNydEuorAsfIfHJij5E2IwYJHyKjN49UI",
	  authDomain: "whizzy-development.firebaseapp.com",
	  databaseURL: "https://whizzy-development.firebaseio.com",
	  projectId: "whizzy-development",
	  storageBucket: "whizzy-development.firebasestorage.app",
	  messagingSenderId: "299227571009",
	  appId: "1:299227571009:web:aa6fb6033a8efaac4737c0",
	},
  FB_TABLES: {
    CSA_CANCELLATION_REASONS_TABLE: 'cancellation_reasons_CSA',
    MONTHLY_BILLING_USERS: 'monthly_billing_users',
    CLUB_PENDING_VERIFICATIONS: 'club_pending_verifications',
    CLUB_VERIFICATION_DECLINE_REASONS: 'club_verification_decline_reasons',
  },
  RAZORPAY: {
    KEY_ID: 'rzp_live_jzypjLoXYk0SKH',
  },
};